// =========================================================
// * Vue Material Dashboard - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";

import firebase from "firebase/compat/app";

import store from "@/store.js";

const firebaseConfig = {
  apiKey: "AIzaSyBU8AqizZ-g_-n2GRzcNrE7W8mzGc1u4n4",
  authDomain: "wflow-josef.firebaseapp.com",
  databaseURL:
    "https://wflow-josef-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wflow-josef",
  storageBucket: "wflow-josef.appspot.com",
  messagingSenderId: "78589251934",
  appId: "1:78589251934:web:13849ef54625265eadbfcf",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// configure router
export const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

Vue.prototype.$Chartist = Chartist;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  data: function () {
    return {
      Chartist: Chartist,
    };
  },
});

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});
